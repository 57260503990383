import React from 'react'

const SimpleErrorMessage = ({ errorHead, errorMessage }) => {
	return (
		<div className='flex items-center justify-center mt-6 text-center'>
			<div role='alert' className='w-full h-20 lg:w-1/2 m-auto'>
				<div className='bg-red-500 text-white font-bold rounded-t px-4 py-2'>
					{errorHead}
				</div>
				<div className='border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700'>
					<p>{errorMessage}</p>
				</div>
			</div>
		</div>
	)
}

export default SimpleErrorMessage
